import styled from 'styled-components'
import React from 'react'

import Arrow from '../../assets/icons/arrow.svg'
import Oval from '../../assets/icons/Oval.svg'
import ShowMoreButton from './components/ShowButton'
import { useTranslation } from 'react-i18next'

function MedicalTerminologySection() {
	const { t } = useTranslation()

	return (
		<Block>
			<Title>
				<b>{t('MedicalTerminologySection.title.en')}</b>
				{t('MedicalTerminologySection.title.plain1')}
				<b>{t('MedicalTerminologySection.title.em')}</b>
			</Title>
			<ContentsBox>
				<ContentsInnerBox>
					<OntologiaOCR>
						The scan reveals a<br />
						heterogeneous,
						<br />
						hypodense lesion
						<br />
						approximately 3 cm in
						<br />
						diameter located in the left
						<br />
						lobe of the liver.
						<br />
						No significant
						<br />
						lymphadenopathy or
						<br />
						ascites noted. Spleen,
						<br />
						pancreas, and :……
					</OntologiaOCR>
					<ContentTitle>Raw Text</ContentTitle>
				</ContentsInnerBox>
				<ArrowCircle>
					<Oval />
					<Arrow />
				</ArrowCircle>
				<ContentsInnerBox>
					<OntologiaLLM>
						is_heterogenous: true
						<br />
						lesion_size: 3<br />
						lesion_size: ‘cm’
						<br />
						location: ‘liver’
						<br />
						sub_location: ‘left_lobe’
						<br />
						has_ascites: false
						<br />
						lymph_lesions:
						<br />
						[[‘lymphadenopathy’,
						<br />
						‘unremarkable’]]
					</OntologiaLLM>
					<ContentTitle>Structured Format</ContentTitle>
				</ContentsInnerBox>
			</ContentsBox>
			<Description>{t('MedicalTerminologySection.description')}</Description>
			<ButtonWrapper>
				<ShowMoreButton
					isBlank={false}
					text={`${t('MedicalTerminologySection.button')}`}
					href="/tech"
				/>
			</ButtonWrapper>
		</Block>
	)
}

export default MedicalTerminologySection

const Block = styled.section`
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 1216px;
		padding: 112px 104px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding: 96px 0;
	}

	@media (max-width: 768px) {
		width: 320px;
		padding: 64px 0;
	}
`

const Title = styled.h1`
	white-space: pre-line;
	margin-bottom: 48px;

	b {
		background: linear-gradient(to right, #fbf216, #35b989);
		color: transparent;
		background-clip: text;
		-webkit-background-clip: text;
	}

	@media (min-width: 1440px) {
		font-size: 60px;
		font-style: normal;
		font-weight: 700;
		line-height: 72px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 56px;
	}

	@media (max-width: 768px) {
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 44px;

		margin-bottom: 32px;
		word-break: keep-all;
	}
`

const ContentsBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;
	background-color: #f5f9ff;

	padding: 48px;

	@media (max-width: 768px) {
		flex-direction: column;
		padding: 20px;
		gap: 24px;
	}
`

const ContentsInnerBox = styled.div`
	& > div {
		@media (min-width: 1440px) {
			width: 402px;
			height: 447px;
			padding: 64px 40px;

			font-size: 25px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px;
		}
		@media (min-width: 768px) and (max-width: 1440px) {
			width: 242px;
			height: 272px;
			padding: 40px 24px;

			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
		}
		@media (max-width: 768px) {
			width: 280px;
			height: 314px;
			padding: 40px 24px;

			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
		}
	}
`

const OntologiaOCR = styled.div`
	border-radius: 20px;
	background: #f2f2f2;
`

const OntologiaLLM = styled.div`
	background: linear-gradient(164deg, #7a007d 11.2%, #1b034e 98.04%);
	border-radius: 20px;
	color: #fff;
`

const ContentTitle = styled.h3`
	text-align: center;
	margin-top: 16px;

	@media (min-width: 1440px) {
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;
	}
	@media (max-width: 768px) {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}
`

const ArrowCircle = styled.div`
	display: flex;
	align-items: center;

	margin-left: 15px;
	border-radius: 100%;

	svg:nth-child(1) {
		width: 60px;
		height: 60px;
	}

	svg:nth-child(2) {
		transform: rotate(90deg);
		margin-right: 37px;
		margin-left: -37px;
	}

	svg:nth-child(2) path {
		fill: black;
	}
`

const Description = styled.p`
	color: #aaaaaa;
	word-break: keep-all;

	@media (min-width: 1440px) {
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;

		padding-left: 230px;
		margin-top: 132px;
		margin-right: -112px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;
		margin-top: 192px;
		word-break: break-all;
	}
	@media (max-width: 768px) {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
		margin-top: 128px;
	}
`

export const ButtonWrapper = styled.div`
	padding-top: 32px;

	@media (min-width: 1440px) {
		padding-top: 16px;
		padding-left: 230px;
	}
`