import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'



import ShowMoreButton from './components/ShowButton'
import { useTranslation } from 'react-i18next'

function AIServiceSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			Ontol: file(relativePath: { eq: "images/main/ontol_main.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			OfC: file(relativePath: { eq: "images/main/ontol_for_clinics.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Block>
			<Title>{t('AIServiceSection.title')}</Title>
			<Introduction>
				<Content>
					<ImageWrapper>
						<OntolImg url={data.Ontol?.childImageSharp.fluid.src} />
					</ImageWrapper>
					<Caption>{t('AIServiceSection.caption1')}</Caption>
					<ContentTitle>{t('AIServiceSection.contentTitle1')}</ContentTitle>
					<ContentDescription>
						{t('AIServiceSection.contentDescription1')}
					</ContentDescription>
					<Min768ButtonWrapper>
						<ShowMoreButton
							href="/product#ontol"
							text={`${t('AIServiceSection.button1')}`}
							isBlank={false}
						/>
					</Min768ButtonWrapper>
					<Max768ButtonWrapper>
					<ShowMoreButton
							href="/product#ontol"
							text={`${t('AIServiceSection.button1')}`}
							isBlank={false}
						/>
					</Max768ButtonWrapper>
				</Content>
				<Content>
					<ImageWrapper>
						<OntolImg url={data.OfC?.childImageSharp.fluid.src} />
					</ImageWrapper>
					<Caption>{t('AIServiceSection.caption2')}</Caption>
					<ContentTitle>{t('AIServiceSection.contentTitle2')}</ContentTitle>
					<ContentDescription>
						{t('AIServiceSection.contentDescription2')}
					</ContentDescription>
					<Min768ButtonWrapper>
						<ShowMoreButton
							href="/product#ontol-for-clinics"
							text={`${t('AIServiceSection.button1')}`}
							isBlank={false}
						/>
					</Min768ButtonWrapper>
					<Max768ButtonWrapper>
						<ShowMoreButton
							href="/product#ontol-for-clinics"
							text={`${t('AIServiceSection.button1')}`}
							isBlank={false}
						/>
					</Max768ButtonWrapper>
				</Content>
			</Introduction>
		</Block>
	)
}

const Block = styled.section`
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 1216px;
		padding: 104px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding: 96px 0;
	}
	@media (max-width: 768px) {
		width: 320px;
		padding: 64px 0;
	}
`

const Title = styled.h1`
	white-space: pre-line;

	@media (min-width: 1440px) {
		font-size: 60px;
		font-style: normal;
		font-weight: 700;
		line-height: 72px;
		margin-bottom: 96px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 56px;
		margin-bottom: 32px;
	}
	@media (max-width: 768px) {
		white-space: normal;
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 44px;
	}
`

const Introduction = styled.div`
	display: flex;
	width: 100%;

	@media (min-width: 1440px) {
		justify-content: flex-end;
		margin-bottom: 100px;
		gap: 74px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 32px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const Content = styled.div`
	@media (min-width: 1440px) {
		width: 384px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 368px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const ImageWrapper = styled.div`
	@media (min-width: 1440px) {
		width: 384px;
		height: 384px;
		margin-bottom: 64px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		width: 328px;
		height: 345px;
		margin-bottom: 48px;
	}

	@media (max-width: 768px) {
		width: 320px;
		height: 337px;
		margin-bottom: 30px;
	}
`

const OntolImg = styled.div<{ url: string }>`
	${({ url }) => css`
		background-image: url(${url});
		background-size: 90%;
		background-repeat: no-repeat;
		background-position: bottom;
	`}
	width: 100%;
	height: 100%;

	@media (min-width: 768px) and (max-width: 1440px) {
		background-size: 100% 95%;
	}
`

const LungAndBubble = styled.div<{ url1: string; url2: string }>`
	${({ url1, url2 }) => css`
		background-image: url(${url2}), url(${url1});
		background-size: 60%, 90%;
		background-position: bottom right, top;
		background-repeat: no-repeat;
	`}

	width: 100%;
	height: 100%;
	@media (min-width: 1440px) {
		margin-bottom: 64px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		background-size: 70% 40%, 90%;
		margin-bottom: 48px;
	}
	@media (max-width: 768px) {
		background-size: 70% 40%, 90%;
		margin-bottom: 24px;
		height: 345px;
	}
`

const Caption = styled.p`
	@media (min-width: 1440px) {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	}
`

export const ContentTitle = styled.h2`
	white-space: pre-wrap;

	@media (min-width: 1440px) {
		font-size: 29px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;

		margin-top: 24px;
		margin-bottom: 20px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 24.5px;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;

		margin-top: 24px;
		margin-bottom: 20px;
	}
	@media (max-width: 768px) {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;

		margin-top: 16px;
		margin-bottom: 16px;
	}
`

export const ContentDescription = styled.p`
	@media (min-width: 1440px) {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 32px;

		margin-bottom: 20px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;

		margin-bottom: 24px;
	}
	@media (max-width: 768px) {
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;

		margin-bottom: 16px;
	}
`

export const ButtonWrapper = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		gap: 20px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 24px;
	}
	@media (max-width: 768px) {
	}
`

export const Max768ButtonWrapper = styled.div`
	display: none;
	@media (max-width: 768px) {
		display: block;
		margin-bottom: 16px;
	}
`

export const Min768ButtonWrapper = styled.div`
	display: none;

	@media (min-width: 1440px) {
		gap: 20px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 24px;
		button {
			min-width: 145px;
		}
	}

	@media (min-width: 768px) {
		display: flex;
	}
`

export default AIServiceSection
