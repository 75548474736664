import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import LinkIcon from '../../assets/icons/link.svg'
import { Link } from 'gatsby'
import { modifyLangString } from '../../lib/utils/modifyLangString'

function PartnersSection() {
	const { t, i18n } = useTranslation()
	const currentLanguage = modifyLangString(i18n.language)

	return (
		<Block>
			<LeftSection>
				<RotatedText language={currentLanguage}>
					{t('PartnerSection.rotatedText')}
				</RotatedText>
				<Title>{t('PartnerSection.title')}</Title>
			</LeftSection>
			<Partners>
				<ul>
					<li>
						<a className="item" href="https://bluepoint.ac/" target="_blank">
							{t('PartnerSection.partner1')}
							<LinkIcon />
						</a>
					</li>
					<li>
						<a
							className="item"
							href="https://www.amc.seoul.kr/asan/main.do"
							target="_blank"
						>
							{t('PartnerSection.partner2')}
							<LinkIcon />
						</a>
					</li>
					<li>
						<a
							className="item"
							href="https://www.ajoumc.or.kr/"
							target="_blank"
						>
							{t('PartnerSection.partner3')}
							<LinkIcon />
						</a>
					</li>
					<li>
						<a
							className="item"
							href="https://www.snuh.org/intro.do"
							target="_blank"
						>
							{t('PartnerSection.partner4')}
							<LinkIcon />
						</a>
					</li>
					<li>
						<a className="item" href="https://seoulrh.com/" target="_blank">
							{t('PartnerSection.partner5')}
							<LinkIcon />
						</a>
					</li>
					<li>
						<a className="item" href="https://dcgen.io/" target="_blank">
							{t('PartnerSection.partner6')}
							<LinkIcon />
						</a>
					</li>
					<li>
						<a
							className="item"
							href="https://www.megazone.com/"
							target="_blank"
						>
							{t('PartnerSection.partner7')}
							<LinkIcon />
						</a>
					</li>
					<li>
						<a className="item" href="https://www.3ds.com/ko/" target="_blank">
							{t('PartnerSection.partner8')}
							<LinkIcon />
						</a>
					</li>
					<li>
						<a
							className="item"
							href="https://www.microsoft.com/ko"
							target="_blank"
						>
							Microsoft
							<LinkIcon />
						</a>
					</li>
					<li>
						<a
							className="item"
							href="https://www.nvidia.com/ko/"
							target="_blank"
						>
							NVidia
							<LinkIcon />
						</a>
					</li>
				</ul>
			</Partners>
		</Block>
	)
}

const Block = styled.section`
	display: flex;
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 1216px;
		padding: 160px 0;
		justify-content: space-between;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding: 96px 0;
		flex-direction: column;
		gap: 48px;
	}

	@media (max-width: 768px) {
		width: 320px;
		padding: 64px 0;
		flex-direction: column;
		gap: 48px;
	}
`

const LeftSection = styled.div`
	display: flex;

	@media (min-width: 1440px) {
	}
	@media (min-width: 768px) and (max-width: 1440px) {
	}
	@media (max-width: 768px) {
	}
`

const RotatedText = styled.p<{ language: string }>`
	${({ language }) => css`
		transform: rotate(-90deg);
		white-space: nowrap;
		width: 32px;
		height: 32px;

		margin-top: 15%;
		margin-right: 16px;

		@media (min-width: 1440px) {
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px;

			margin-top: ${language === 'ko' ? '15%' : '23%'};
		}
		@media (min-width: 768px) and (max-width: 1440px) {
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 28px;

			margin-top: ${language === 'ko' ? '10%' : '19%'};
		}
		@media (max-width: 768px) {
			display: none;
		}
	`}
`

const Title = styled.h1`
	flex-grow: 1;
	white-space: pre-line;
	@media (min-width: 1440px) {
		font-size: 60px;
		font-style: normal;
		font-weight: 700;
		line-height: 72px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 56px;
	}
	@media (max-width: 768px) {
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 44px;
	}
`

const Partners = styled.div`
	ul {
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	li {
		margin-left: auto;
		list-style: none;
	}

	li a::after {
		display: block;
		content: '';
		width: 0px;
		height: 3px;
		background: rgb(0, 0, 0);
		transition: width 0.2s ease-in-out 0s;
	}

	li:hover a::after {
		display: block;
		content: '';
		width: 100%;
		height: 3px;
		background: rgb(0, 0, 0);
		transition: width 0.2s ease-in-out 0s;
	}

	@media (min-width: 1440px) {
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		margin-left: auto;
	}
	@media (max-width: 768px) {
	}

	svg {
		position: relative;
		width: 10px;
		height: 10px;
		top: -20px;
		left: 8px;
		opacity: 0.5;
		@media (min-width: 1440px) {
		}
		@media (min-width: 768px) and (max-width: 1440px) {
			margin-left: auto;
		}
		@media (max-width: 768px) {
		}
	}

	li:hover svg {
		opacity: 1;
	}

	.item {
		@media (min-width: 1440px) {
			font-size: 32px;
			font-style: normal;
			font-weight: 700;
			line-height: 40px;
		}
		@media (min-width: 768px) and (max-width: 1440px) {
			font-size: 28px;
			font-style: normal;
			font-weight: 700;
			line-height: 36px;
		}
		@media (max-width: 768px) {
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: 32px;
		}
	}
`

export default PartnersSection
